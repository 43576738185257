import React from 'react';
import pies from '../images/pies.svg';
import logo from '../images/logo.svg';

const Header = () => {
   return (
    <div className="container">
      <div className='grid grid-cols-3 items-center gap-10 p-2'>
        <div className='text-center'><img src={pies} className='pies' alt="pies" /></div>
        <div className='text-center'><img src={logo} className='logo' alt="logo" /></div>
        <div className='text-center'><img src={pies} className='pies' alt="pies" /></div>
      </div>
    </div>
  )
}

export default Header;