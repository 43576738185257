import Header from './components/Header';
import FoodPics from './components/FoodPics';
import Chatbot from './components/Chatbot';

export function App() {
  return (
    <div>
      <Header />
      <div className='banner'>
        <div className='container text-center'>
          <h1>WELCOME BAKER'S DOZEN FANS!</h1>
        </div>
      </div>
      <div className='container text-center py-14'>
        <div className='mx-auto	w-3/4'>
          <p>Our baking show is the sweetest around, but we could never have made it happen without you! It's been a long journey, with a lot of sugar highs and lows, but we made it here together. Thank you so much for your love and support.</p>
          <p>Be sure to keep up with each step of our journey through the mouthwatering world of bakertainment. Episodes of Baker's Dozen are available on Mixflix now!</p>
        </div>
      </div>
      <FoodPics />
      <div className='container text-center py-14'>
        <div className='mx-auto	w-3/4'>
          <h2 className='pb-4'>WE LOAF HEARING FROM YOU</h2>
          <p class="px-8">Which baker are you rooting for? What are your favorite baking puns? Do you have a favorite recipe you'd like to share with us? Let us know why you're our biggest flan. Write us below!</p>
        </div>
      </div>
      <div className='mini-banner'></div>
      <Chatbot />
      <div className='mini-banner'></div>
  </div>
  );
}


