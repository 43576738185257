import React from 'react';
import cupcakes from '../images/cupcakes.jpg';
import baked_goods from '../images/baked-goods.jpg';
import pastries from '../images/pastries.jpg';

const FoodPics = () => {
   return (
    <div className='grid grid-cols-3 items-center gap-0'>
      <div className='text-center'><img src={pastries} alt="pies" /></div>
      <div className='text-center'><img src={cupcakes} alt="logo" /></div>
      <div className='text-center'><img src={baked_goods} alt="pies" /></div>
    </div>
  )
}

export default FoodPics;