import React, { useState  } from 'react';
import axios from 'axios';

const Chatbot = () => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [conversationState, setConversationState] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [showPrompts, setShowPrompts] = useState(false);
  const [promptQnaId, setPromptQnaId, getPromptQnaId] = useState('');
  const currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  
  const sendMessage = async () => {
    try {
      const qnaEndpoint = 'https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/6552e4d1-e22b-48fb-a831-16ebefb5e4d2/generateAnswer';
      const subscriptionKey = '69ccf40a-ac15-4259-841d-97e4ad9f382d';

      if (conversationState == "") {
        // console.log('conversationState is empty');
        response = await axios.post(
          qnaEndpoint,
          {
            question: userInput,
            "top": 1
          },
          {
            headers: {
              Authorization: `EndpointKey ${subscriptionKey}`,
              'Content-Type': 'application/json'
            }
          }
        );
      } else {
        // console.log('conversationState is not empty');
        response = await axios.post(
          qnaEndpoint,
          {
            question: userInput,
            "top": 1,
            "context": {
              "previousQnAId": conversationState
            }
          },
          {
            headers: {
              Authorization: `EndpointKey ${subscriptionKey}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }
      
      // console.log('Response:', response.data.answers[0]);
      const botReply = response.data.answers[0].answer;
      const newConversationState = response.data.answers[0].id;
      const updatedChatHistory = [
        ...chatHistory,
        { message: userInput, sender: 'user', time: currentTime },
        { message: botReply, sender: 'bot', time: currentTime }
      ];
      setConversationState(newConversationState);
      setChatHistory(updatedChatHistory);
      setUserInput('');
      
      
      if (response.data.answers[0].context.prompts.length > 0) {
        setPrompts(response.data.answers[0].context.prompts);
        setShowPrompts(true);
      } else {
        setShowPrompts(false);
      }
      

    } catch (error) {
      console.log('Error fetching response:', error);
    }
  };
  const sendQnaMessage = async (prompt) => {
    try {
      const qnaEndpoint = 'https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/6552e4d1-e22b-48fb-a831-16ebefb5e4d2/generateAnswer';
      const subscriptionKey = '69ccf40a-ac15-4259-841d-97e4ad9f382d';
      
      response = await axios.post(
        qnaEndpoint,
        {
          qnaId: prompt.qnaId,
          "top": 1
        },
        {
          headers: {
            Authorization: `EndpointKey ${subscriptionKey}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // console.log('Response:', response.data.answers[0]);
      const botReply = response.data.answers[0].answer;
      const newConversationState = response.data.answers[0].id;
      const botPrompts = response.data.answers[0].context.prompts;
      const updatedChatHistory = [
        ...chatHistory,
        { message: prompt.displayText, sender: 'user', time: currentTime },
        { message: botReply, sender: 'bot', time: currentTime }
      ];
      setConversationState(newConversationState);
      setChatHistory(updatedChatHistory);
      setUserInput('');

      if (botPrompts.length > 0) {
        setPrompts(botPrompts);
        setShowPrompts(true);
      } else {
        setShowPrompts(false);
      }

    } catch (error) {
      console.log('Error fetching response:', error);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      sendMessage();
    }
  };
  const handlePromptClick = (prompt) => {
    // console.log('Prompt clicked:', prompt);
    // console.log('Prompt clicked ID:', prompt.qnaId);
    setUserInput(prompt.displayText);
    setPromptQnaId(prompt.qnaId, function(){});
    setShowPrompts(false);
    sendQnaMessage(prompt)
  };

  return (
    <div className='container my-14'>
      <h2 className='text-center mb-14'>CHAT WITH HELENA HADDOCK</h2>
      <div id="hak-messenger-client" className='w-4/5 mx-auto'>
        <div className="card chat-app">
          <div id="plist" className="people-list">
            <ul className="list-unstyled chat-list mt-2 mb-0">
              <li className="clearfix active">
                <img src="https://cdn.huntakiller.com/bakers-dozen/avatar-helena.jpg" alt="avatar" />
                <div className="about">
                  <div className="name">Helena Haddock</div>
                  <div className="status"> <i className="fa fa-circle online"></i> online </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="chat">
            <div className="chat-header clearfix">
              <div className="row">
                <div className="col-lg-12">
                  <a data-toggle="modal" data-target="#view_info">
                    <img src="https://cdn.huntakiller.com/bakers-dozen/avatar-helena.jpg" alt="avatar" />
                  </a>
                  <div className="chat-about">
                    <h6 className="m-b-0">Helena Haddock</h6>
                    <small>Last seen: 0 minutes</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-history">
              <ul className="m-b-0 chat-container" id="chat-history-message-list">
                <li className="clearfix received">
                  <div className="message-data">
                    <img src="https://cdn.huntakiller.com/bakers-dozen/avatar-helena.jpg" alt="avatar" />
                    <span className="message-data-time me-3 welcome-message-time">{ currentTime }</span>
                  </div>
                  <div className="message other-message"><span className="answer">Hey there! I see someone has logged on. What is your name?</span></div>
                </li>
                {/* Display chat history */}
                {chatHistory.map((chat, index) => (
                  <div key={index} className={chat.sender}>
                    {chat.sender === 'bot' ? (
                      <li className="clearfix received">
                        <div className="message-data">
                          <img src="https://cdn.huntakiller.com/bakers-dozen/avatar-helena.jpg" alt="avatar" />
                          <span className="message-data-time me-3 welcome-message-time">{ chat.time }</span>
                        </div>
                        <div className="message other-message"><span className="answer">{chat.message}</span></div>
                      </li>
                    ) : (
                      <li className="clearfix sent">
                        <div className="message-data text-end">
                          <span className="message-data-time me-3">{ chat.time }</span>
                          <img src="https://cdn.huntakiller.com/bakers-dozen/avatar-anonymous.jpg" alt="avatar" />
                        </div>
                        <div className="message my-message float-right my-message">{ chat.message }</div>
                      </li>
                    )}
                  </div>
                ))}
                {/* Display prompts as choices */}
                {showPrompts && (
                  <div className="prompts-container">
                    {prompts.map(item => (
                      <div
                        className="prompt"
                        onClick={() => handlePromptClick(item)}
                      >
                       {item.displayText}
                      </div>
                    ))}
                  </div>
                )}
              </ul>
            </div>
            <div className="chat-message clearfix">
              <div className="flex">
                <div className="arrow-box">
                  <span className="input-group-text"><i className="fa fa-send"></i></span>
                </div>
                <input
                  id="hak-messenger-client-message" 
                  className="form-input basis-11/12" 
                  type="text"
                  placeholder="Type your message..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  />
                <button type="button" 
                  id="hak-messenger-client-submit" 
                  className="btn" 
                  onClick={sendMessage} >
                  Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;